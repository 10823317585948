import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from "@angular/forms";
import { ArticleService } from "../service/article.service";
import { DataService } from "../service/DataService";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as model from '../model/index';
import * as CanvasJSAngularChart from '../../assets/canvasjs.angular.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  color: string;
  cols: number;
  rows: number;
  text: string;
  tiles: any;
  datapoints: any = [];
  displayedColumns: String[] = ['ClubID', 'ClubName', 'Total Slots', 'Available Slots', 'Description'];
  dataSource: any;
  ClubdisplayedColumns : String[] = ['ClubID', 'ClubName', 'TotalSlots', 'AvailableSlots'];
  ClubdataSource : any;
  Clubform: FormGroup;
  userform: FormGroup;
  adduserform: model.User = {};
  userList: any;
  Name: string;
  ClubId: string;
  UserID: string;
  Dob: string;
  EmailID: string;
  MobileNo: Number;
  Preferences: string;
  ClubName: string;
  AvailableSlots: string;
  TotalSlots: string;
  Description: string;
  PreferencesList: any = [];
  columnChartOptions: any;
  pieChartOptions: any;
  lineChartOptions: any;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('Clubpaginator') Clubpaginator: MatPaginator;
  constructor(private dataService: DataService, private toaster: ToastrService, private serviceProvider: ArticleService, private router: Router) {
    this.getUsers();
    this.getClub();
  }

  ngOnInit(): void {
    this.Name = JSON.parse(localStorage.getItem('currentUserName'));
    var id = JSON.parse(localStorage.getItem('id'));
    this.addformControl(null);
    this.getUser(id);
    this.tiles = [
      { text: 'One', cols: 3, rows: 1, color: 'lightblue' },
      { text: 'Two', cols: 1, rows: 2, color: 'lightgreen' },
      { text: 'Three', cols: 1, rows: 1, color: 'lightpink' },
      { text: 'Four', cols: 2, rows: 1, color: '#DDBDF1' },
    ];
  }

  addformControl(data) {
    this.Clubform = new FormGroup({
      Preferences: new FormControl(''),
    });
  }

  getUsers() {
    this.serviceProvider.getuser().subscribe(result => {
      var i = 1;
      for (const temp of result) {
        this.columnChartOptions = {
          animationEnabled: true,
          title: {
            text: 'Users',
          },
          data: [
            {
              // Change type to "doughnut", "line", "splineArea", etc.
              type: 'column',
              dataPoints: [
                { label: temp.Name, y: i },
              ],
            },
          ],
        };
        i++;
      }
    });
  }

  getUser(id) {
    this.serviceProvider.getsingleuser(id).subscribe(result => {
      this.Name = result.Name;
      this.UserID = result.UserID;
      this.Dob = result.Dob;
      this.EmailID = result.UserID;
      this.MobileNo = result.UserID;
      this.serviceProvider.getClubByID(result.ClubID).subscribe(res => {
        this.ClubId = res[0].ClubID;
        this.Preferences = (result.ClubID === '') ? '' : res[0].ClubName;
        this.ClubName = res[0].ClubName;
        this.AvailableSlots = res[0].AvailableSlots;
        this.TotalSlots = res[0].TotalSlots;
        this.Description = res[0].Description;
      });
    });
  }

  getClub() {
    this.serviceProvider.getClub().subscribe(result => {
      for (const temp of result) {
        this.datapoints.push({
          label: temp.ClubName + "/" + temp.TotalSlots, y: temp.AvailableSlots 
        });
        this.columnChartOptions = {
          animationEnabled: true,
          title: {
            text: 'Clubs',
          },
          data: [
            {
              // Change type to "doughnut", "line", "splineArea", etc.
              type: 'column',
              dataPoints: this.datapoints
            },
          ],
        };

        this.pieChartOptions = {
          animationEnabled: true,
          title: {
            text: 'Clubs',
          },
          theme: 'light2', // "light1", "dark1", "dark2"
          data: [
            {
              type: 'pie',
              dataPoints: this.datapoints
            },
          ],
        };

        this.lineChartOptions = {
          animationEnabled: true,
          title: {
            text: 'Clubs',
          },
          theme: 'light2', // "light1", "dark1", "dark2"
          data: [
            {
              type: 'line',
              dataPoints: this.datapoints
            },
          ],
        };
        if (temp.AvailableSlots > 0) {
          this.PreferencesList.push(temp);
        }
      }
      
      this.ClubdataSource = new MatTableDataSource<any>(result);
      this.ClubdataSource.paginator = this.Clubpaginator;
    });
  }

}

<header>
  <app-header></app-header>
</header>
<body style="background-color: #f2f2f2;">
  <main>
    <router-outlet></router-outlet>
  </main>
</body>
<footer>
  <app-footer></app-footer>
</footer>
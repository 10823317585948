
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { CountdownModule } from 'ngx-countdown';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { ArticleService } from './service/article.service';
import { ExcelService } from './service/excel-service';
import { TwoDigitDecimaNumberDirective } from './two_digit';
import { AuthGuard } from './auth.guard';
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from './date.formate';
import { DateAdapter } from '@angular/material/core';
import { QuillModule } from 'ngx-quill';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { HeaderComponent } from './header/header.component';
import { SigninComponent } from './signin/signin.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { DisableControlDirective } from './disableInput';
import { NgxPrintModule } from 'ngx-print';
import { saveAs } from 'file-saver';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatExpansionModule } from '@angular/material/expansion';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { DemoMaterialModule } from './material-module';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { GoogleAnalyticsService } from "./service/google-analytics-events.service";
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { CalendarModule } from 'angular-calendar';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
// import { CKEditorModule } from 'ckeditor4-angular';
// import { CKEditor5, CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import { MatTabsModule } from '@angular/material/tabs';
import * as CanvasJSAngularChart from '../assets/canvasjs.angular.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;
import { SortPipe } from './shared/pipes/sort.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServicedeclinedComponent } from './servicedeclined/servicedeclined.component';
import { HelpComponent } from './help/help.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from '../app/service/http-interceptor';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { DataService } from './service/DataService';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { AdminComponent } from './admin/admin.component';
import { ClubsComponent } from './clubs/clubs.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { UserdashboardComponent } from './userdashboard/userdashboard.component';
import { ProfileComponent } from './admin/profile/profile.component';

@NgModule({
  declarations: [
    CanvasJSChart,
    AppComponent,
    HeaderComponent,
    SigninComponent,
    DashboardComponent,
    DisableControlDirective,
    ResetPasswordComponent,
    PagenotfoundComponent,
    SortPipe, 
    ServicedeclinedComponent, 
    HelpComponent, 
    AdminComponent, ClubsComponent, HomeComponent, UserdashboardComponent, ProfileComponent, 
  ],
  imports: [
    // MatSelectFilterModule,
    CountdownModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    // NgxSliderModule,
    // NgxYoutubePlayerModule,
    RecaptchaModule,
    GalleryModule,
    LightboxModule,
    // RecaptchaFormsModule,
    BrowserModule,
    NgxStarRatingModule,
    NgxPrintModule,//CKEditorModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatTabsModule,
    QuillModule.forRoot(),
    ToastrModule.forRoot(),
    MatTableModule,
    DemoMaterialModule,
    MatSortModule,
    HttpClientModule,
    MatPaginatorModule,
    AppRoutingModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    ReactiveFormsModule,
    AppRoutingModule,
    PdfViewerModule,
    MatExpansionModule,
    MatCheckboxModule,
    FormsModule,
    CommonModule,
    NgxDocViewerModule,
    NgSelectModule,
    NgbModule,
    //CKEditorComponent
  ],
  providers: [DataService, ArticleService, ExcelService, AuthGuard, GoogleAnalyticsService, DatePipe, SortPipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    }
  ],
    schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  // entryComponents: []
})
export class AppModule { }

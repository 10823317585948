import { Component, Input, OnInit, Inject, ViewChild, AfterContentInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ArticleService } from '../service/article.service';
import { SigninComponent } from '../signin/signin.component';
import { ToastrService } from "ngx-toastr";
import { environment } from "../../environments/environment";
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import * as moment from 'moment';
import { SelectionModel } from '@angular/cdk/collections';
import { ContactUs, EmailApiKeys, EmailTemplate } from '../model/index';
import { inArray } from 'jquery';
import { concat } from 'rxjs';
import { map } from "rxjs/operators";
import { Model } from 'mongoose';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterContentInit {
  contactusform: FormGroup;
  addContact: ContactUs = {};
  public EmailTemplate: EmailTemplate = {};
  public EmailTemplateList: any = [];
  isLoggedIn$: Observable<boolean>;
  loginid: any;
  userId: any;
  institution: any;
  facebook: any;
  google: any;
  instragram: any;
  twitter: any;
  youtube: any;
  logo: string;
  public mobile: string;
  public emailId: string;
  public address: string;
  public isHiddenHeader1 = false;
  public isHiddenHeader2 = false;
  public url = environment.url;
  public dynamicPath: any;
  username: any;
  headermenulist: any = [];
  subheadermenulist: any = [];
  header: string;

  constructor(private serviceProvider: ArticleService, private toastr: ToastrService, private router: Router) {
    var headername = window.location.href.split('/').slice(-1)[0];
    if (headername === 'user') {
      this.header = "Manage Users";
    } else if (headername === 'Club') {
      this.header = "Manage Clubs";
    } else if (headername === 'dashboard') {
      this.header = "Welcome Admin Dashboard";
    } else if (headername === 'userdashboard') {
      this.header = "Welcome User Dashboard";
    }
    this.tokenChecking();
  }

  ngOnInit(): void {
    this.logo = "../../assets/logo.png";
    this.getheadername();
    this.addFormControl();
    this.username = JSON.parse(localStorage.getItem("currentUserName"));
    this.loginid = JSON.parse(localStorage.getItem("id"));
    if (this.loginid !== null) {
      this.getuser(this.loginid);
    }
    this.isLoggedIn$ = this.serviceProvider.isLoggedIn;
    this.tokenChecking();
  }
  ngAfterContentInit() {
    $('.leads-header #sidebar-wrapper .dropdown .dropdown-toggle').on('click', function () {
      $(this).next().toggle();
    });
  }

  addFormControl() {
    this.contactusform = new FormGroup({
      name: new FormControl("", Validators.required),
      emailid: new FormControl("", [Validators.required, Validators.email]),
      mobileno: new FormControl("", [Validators.required, Validators.maxLength(10)]),
      message: new FormControl("", Validators.required),
    });
  }

  getheadername() {
    var headername = window.location.href.split('/').slice(-1)[0];
    if (headername === 'user') {
      this.header = "Manage Users";
    } else if (headername === 'Club') {
      this.header = "Manage Clubs";
    } else if (headername === 'adminprofile') {
      this.header = "Admin Profile";
    } else if ((headername === 'dashboard') || (headername === 'userdashboard')){
      this.header = "Dashboard";
    } 
  }

  dashboardlink() {
    if(this.username === 'Admin') {
      this.router.navigate([`/dashboard`]);
    } else if(this.username !== 'Admin') {
      this.router.navigate([`/userdashboard`]);
    }
  }

  // Get User Details
  getuser(data) {
    this.serviceProvider.getsingleuser(data).subscribe(res => {
      this.username = res.Name;
      this.contactusform.controls[`name`].setValue(this.username);
      this.contactusform.controls[`emailid`].setValue(res.email);
      this.contactusform.controls[`mobileno`].setValue(res.mobile);
    });
  }

  tokenChecking() {
    if (localStorage.getItem("token") === null) {
      this.isHiddenHeader1 = false;
      this.isHiddenHeader2 = true;

    }
    else if (localStorage.getItem("token") !== null) {
      this.isHiddenHeader1 = true;
      this.isHiddenHeader2 = false;
    }
  }

  signIn() {
    this.isHiddenHeader1 = true;
    this.isHiddenHeader2 = true;
  }

  signOut() {
    localStorage.removeItem('token');
    this.isHiddenHeader1 = false;
    this.isHiddenHeader2 = true;
  }

  leadtogg() {
    $('#wrapper').toggleClass('toggled');
    if ($('#wrapper').hasClass('toggled')) {
      $('#menu-toggle').removeClass('disableicon').addClass('enableicon');
      $('#menu-txt-toggle').removeClass('enableicon').addClass('disableicon');
      if ($(window).width() > 768) {
        $('.leads-header .navbar').css({ 'width': '100%', 'left': '0px' });
        $('#wrapper-section').css({ 'width': '100%', 'margin-left': '0px' });
      }
    }
    else {
      $('#menu-txt-toggle').removeClass('disableicon').addClass('enableicon');
      if ($(window).width() > 768) {
        $('.leads-header .navbar').css({ 'width': '82.4%', 'left': '17.6%' });
        $('#wrapper-section').css({ 'width': '82.4%', 'margin-left': '17.6%' });
      }
    }
  }

  leadIcnBtn() {
    if ($('#sidebar-wrapper').hasClass('slideInSideMnu')) {
      $('#menu-txt-toggle').removeClass('disableicon').addClass('enableicon');
      $('#menu-toggle').removeClass('disableicon').addClass('enableicon');
      if ($(window).width() > 768) {
        $('.leads-header .navbar').css({ 'width': '82.4%', 'left': '17.6%' });
        $('#wrapper-section').css({ 'width': '82.4%', 'margin-left': '17.6%' });
        $('.leads-header #sidebar-wrapper .dropdown .dropdown-menu').addClass('drp-togg-w');
      }
    } else {
      $('#menu-toggle').removeClass('enableicon').addClass('disableicon');
      if ($(window).width() > 768) {
        $('.leads-header .navbar').css({ 'width': '95%', 'left': '5%' });
        $('#wrapper-section').css({ 'width': '95%', 'margin-left': '5%' });
        $('.leads-header #sidebar-wrapper .dropdown .dropdown-menu').addClass('drp-togg-w');
      }
    }

    const sideTxt = $('#sidebar-wrapper').find('.side-pan-txt');
    $(sideTxt).toggle();
    $('#sidebar-wrapper').toggleClass('slideInSideMnu slideOutSideMnu');
    $('#sidebar-wrapper .nav-item').toggleClass('slideInMenutxt slideOutMenutxt');
  }

  newticket() {
    window.open("https://ABC.Com.freshdesk.com/support/tickets/new");
  }

  fees() {
    window.open("https://ABC.Com.bit.ai/docs/view/dPd0GVC1ZznG11ya");
  }

  helpdesk() {
    window.open("https://ABC.Com.freshdesk.com");
  }

  contactus() {
    $('#contactus').modal("show");
  }

  contactsave() {
    this.addContact.name = this.contactusform.controls[`name`].value;
    this.addContact.emailid = this.contactusform.controls[`emailid`].value;
    this.addContact.mobileno = this.contactusform.controls[`mobileno`].value;
    this.addContact.message = this.contactusform.controls[`message`].value;
    this.serviceProvider.createcontactus(this.addContact).subscribe(res => {
      this.EmailTemplateList.push({
        subject: "Contact Form",
        fromName: this.addContact.name,
        fromEmail: this.addContact.emailid,
        emailId: "ABC.Com.help@gmail.com",
        text: this.addContact.message,
        // emailtemplatedetails: this.InsDetails.email_api
      });
    });
  }
  socialmedia(data) {
    if (data === "facebook") {
      window.open(`${this.facebook}`);
    } else if (data === "googleplus") {
      window.open(`${this.google}`);
    } else if (data === "twitter") {
      window.open(`${this.twitter}`);
    } else if (data === "youtube") {
      window.open(`${this.youtube}`);
    } else if (data === "instagram") {
      window.open(`${this.instragram}`);
    }
  }
}

<section class="scroll-hidden">
    <section [hidden]="isHiddenHeader1">
        <div id="FE-Header">
            <section class="FE-Header-comp">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="ed-com-t1-left">
                                <img src="{{logo}}" class="img-responsive logoimg" alt="LOGO">
                            </div>
                            <div class="ed-com-t1-right">
                                <ul>
                                    <li><a [routerLink]="['/signin']" style="color: #000;" routerLinkActive="router-link-active"
                                            data-toggle="modal" data-target="#modal1" (click)="signIn()">Sign In</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </section>

    <section [hidden]="isHiddenHeader2">
        <div class="d-flex leads-header" id="wrapper" *ngIf="isLoggedIn$ | async as isLoggedIn">
            <div class="bg-light border-right" id="sidebar-wrapper">
                <div class="list-group list-group-flush">
                    <a class="nav-item">
                        <div class="nav-link">
                            <a class="navbar-brand" href="#">
                                <img src="../assets/logo.png" alt="ABC.Com" class="img-responsive brand-img" style="width: 150px;height: 150px;">
                                <!-- <h2 id="logo">ABC.Com</h2> -->
                            </a>
                        </div>
                    </a>                    
                    <a class="nav-item" *ngIf="username === 'Admin'" (click)="getheadername()">
                        <div class="nav-link">
                            <i class="fa fa-tachometer"></i>
                            <span class="side-pan-txt">
                                <span [routerLink]="['/dashboard']"> &nbsp;Dashboard </span> 
                            </span>
                        </div>
                    </a>                                        
                    <a class="nav-item" (click)="getheadername()">
                        <div class="nav-link">
                            <i class="fa fa-tachometer"></i>
                            <span class="side-pan-txt">
                                <span [routerLink]="['/userdashboard']"> &nbsp;Profile </span> 
                            </span>
                        </div>
                    </a>
                    <a class="nav-item dropdown" *ngIf="username === 'Admin'" (click)="getheadername()">
                        <div class="nav-link">
                            <i class="fa fa-users"></i>
                            <span class="side-pan-txt">
                                <span [routerLink]="['/user']"> &nbsp;Manage Users</span>
                            </span>
                        </div>                        
                    </a>    
                    <a class="nav-item dropdown" *ngIf="username === 'Admin'" (click)="getheadername()">
                        <div class="nav-link">
                            <i class="fa fa-users"></i>
                            <span class="side-pan-txt">
                                <span [routerLink]="['/Club']"> &nbsp;Manage Clubs</span>
                            </span>
                        </div>                        
                    </a>                
                </div>
            </div>

            <div id="page-content-wrapper">
                <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                    <!-- <button class="btn btn-success btn-fill btn-round btn-icon" mat-menu-item id="header">
                        <i class="fa fa-ellipsis-v visible-on-sidebar-regular"></i>&nbsp; 
                        {{header}}
                    </button> -->
                    <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
                        <!-- <li class="nav-item active">
                            <a class="nav-link" (click)="dashboardlink()">
                                <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon>
                                <span class="sr-only">(current)</span>
                            </a>
                        </li> -->
                        <li class="nav-item">
                            <button mat-menu-item [matMenuTriggerFor]="profile" id="userdiv">
                                <mat-icon aria-hidden="false">account_circle</mat-icon>Welcome {{username}}
                            </button>
                            <mat-menu #profile="matMenu">
                                <button mat-menu-item *ngIf="username === 'Admin'">
                                    <a class="nav-link" [routerLink]="['/adminprofile']" style="color: black;">
                                        <i class="fa fa-user-circle"></i>&nbsp;
                                        My Profile
                                    </a>
                                </button>
                                <!-- <button mat-menu-item>
                                    <a class="nav-link" [routerLink]="['/reset-password/',loginid]"
                                        style="color: black;">
                                        <i class="fa fa-cog"></i>&nbsp;
                                        Reset Password
                                    </a>
                                </button> -->
                                <button mat-menu-item>
                                    <a class="nav-link" [routerLink]="['/signin']" (click)="signOut()" style="color: black;">
                                        <i class="fa fa fa-power-off"></i>&nbsp;
                                        Sign Out
                                    </a>
                                </button>
                            </mat-menu>
                        </li>
                        <li class="nav-item">
                            <button class="btn nav-link" id="menu-toggle" (click)="leadtogg()"><i class="fa fa-list"
                                    aria-hidden="true"></i></button>
                        </li>
                        <li class="nav-item">
                            <button class="btn nav-link" id="menu-txt-toggle" (click)="leadIcnBtn()"><i
                                    class="fa fa-th-large" aria-hidden="true"></i></button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>
</section>
<div id="contactus" class="modal fade">
    <div class="modal-dialog modal-confirm" style="max-width:50%;max-height: 100%;">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="material-icons">&#xE5CD;</i>
                </div>
                <h4 class="modal-title">Contact Us</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="contactusform">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <input type="text" name="name" matInput placeholder="Name" formControlName="name"
                                        [readonly]=true>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <input type="text" name="emailid" matInput placeholder="EmailID"
                                        formControlName="emailid" [readonly]=true>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <span matPrefix>+91 &nbsp;</span>
                                    <input type="number" name="mobileno" matInput placeholder="MobileNo"
                                        formControlName="mobileno" [readonly]=true>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <mat-label>Message</mat-label>
                                <br /><br />
                                <quill-editor formControlName="message">
                                </quill-editor>
                                <br>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="contactsave()">Submit</button>
            </div>
        </div>
    </div>
</div>
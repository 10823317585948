import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ArticleService } from "../service/article.service";
import { DataService } from "../service/DataService";
import { ToastrService } from 'ngx-toastr';
import * as moment from "moment";
import { Router } from '@angular/router';
import * as model from '../model/index';
import { animate, state, style, trigger, transition } from "@angular/animations";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from "@angular/material/sort";
declare var $: any;

@Component({
  selector: 'app-userdashboard',
  templateUrl: './userdashboard.component.html',
  styleUrls: ['./userdashboard.component.css'],
  animations: [
    trigger('detailExpand',
    [
        state('collapsed, void', style({ height: '0px'})),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class UserdashboardComponent implements OnInit {

  userform: FormGroup;
  adduserform: model.User = {};
  userList: any;
  Name: string;
  ClubId: string;
  UserID: string;
  UserObjID: string = '';
  Dob: string;
  EmailID: string;
  MobileNo: Number;
  Preferences: string;
  Password: string;
  Club: string;
  ClubName: string;
  AvailableSlots: string;
  TotalSlots: string;
  Description: string;
  PreferencesList: any = [];
  addcounterid: string = '';
  Clublist: any = [];
  ProfileImage: string;
  // Profile Picture
  public profile_picturefiles: any = [];
  public profile_picture: string;
  public profile_pictureFile: Array<{ name: string, caption: string }> = [];
  public profile_picturefileSize = true;
  public profile_picturefileValue: string;
  public profile_picturefilename: string;
  
  // File Upload   
  public deleteIndex: number;
  public deleteId: string;
  public deletedata: any = [];
  private selectedFile: File = null;
  private currentId: number;  
  public progress = 0;
  public msg: string;
  public ClubList: any = [];
  public dataSource: any = [];
  columnsToDisplay : string[] = ['ClubName', 'AvailableSlots'];
  expandedElement : any | null;
  @ViewChild('paginator') paginator: MatPaginator;
  constructor(private dataService: DataService, private toaster: ToastrService, private serviceProvider: ArticleService, private router: Router) { }

  ngOnInit(): void {
    this.userformControl(null);
    this.getClub();
    this.Name = JSON.parse(localStorage.getItem('currentUserName'));
    this.UserObjID = JSON.parse(localStorage.getItem('id'));
    this.getUser(this.UserObjID);
  }

  userformControl(data) {
    if (data === null) {
      this.userform = new FormGroup({
        UserID: new FormControl(''),
        Name: new FormControl(''),
        Dob: new FormControl(''),
        EmailID: new FormControl(''),
        MobileNo: new FormControl('', Validators.required),
        ClubID: new FormControl(''),
        profile_picture: new FormControl(''),
      });
      this.UserObjID = '';
      this.ProfileImage = '';
      this.profile_picturefilename = '';
    } else {
      this.userform = new FormGroup({
        UserID: new FormControl(data.UserID),
        Name: new FormControl(data.Name),
        Dob: new FormControl(data.Dob),
        EmailID: new FormControl(data.EmailID),
        MobileNo: new FormControl(data.MobileNo, Validators.required),
        ClubID: new FormControl(data.ClubName),
        profile_picture: new FormControl(data.ProfileImage),
      });
      this.profile_picturefilename = data.ProfileImage;
      this.adduserform.ProfileImage = data.ProfileImage;
    }
  }

  getUser(id) {
    this.serviceProvider.getsingleuser(id).subscribe(result => {
      this.Name = result.Name;
      this.UserID = result.UserID;
      this.Dob = result.Dob;
      this.EmailID = result.EmailID;
      this.MobileNo = result.MobileNo;
      this.ProfileImage = result.ProfileImage;
      this.serviceProvider.getClubByID(result.ClubID).subscribe(res => {
        this.ClubId = res[0].ClubID;
        this.Preferences = (result.ClubID === '') ? '' : res[0].ClubName;
        this.ClubName = res[0].ClubName;
        this.AvailableSlots = res[0].AvailableSlots;
        this.TotalSlots = res[0].TotalSlots;
        this.Description = res[0].Description;
      });
    });
  }

  getClub() {
    this.serviceProvider.getClub().subscribe(result => {
      this.ClubList = result;
      this.dataSource = new MatTableDataSource<any>(this.ClubList);
      setTimeout(() => this.dataSource.paginator = this.paginator);
      for(const temp of result){
        if(temp.AvailableSlots > 0){
          this.PreferencesList.push(temp);
        }
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  editinfo() {
    this.serviceProvider.getsingleuser(this.UserObjID).subscribe(result => {
      this.userformControl(result);
      this.Password = (result.Password !== '') ? result.Password : result.MobileNo;
      this.userform.controls[`UserID`].setValue(result.UserID);
      this.userform.controls[`Name`].setValue(result.Name);
      this.userform.controls[`Dob`].setValue(moment(result.Dob).format("DD-MM-YYYY"));
      this.userform.controls[`EmailID`].setValue(result.EmailID);
      this.userform.controls[`MobileNo`].setValue(result.MobileNo);
      this.userform.controls[`ClubID`].setValue(result.ClubID);
      this.userform.controls[`profile_picture`].setValue(result.ProfileImage);
      this.Club = result.ClubID;
      $('#usermodal').modal("show");
    });
  }

  changepreference(data) {
    if (this.UserObjID !== '') {
      this.serviceProvider.getsingleuser(this.UserObjID).subscribe(result => {
        this.addcounterid = result.ClubID;
      });
    }
  }

  Submit() {
    $('#confirmationmodal').modal('show');
  }

  Confirm() {
    this.adduserform.UserID = this.userform.controls[`UserID`].value;
    this.adduserform.Name = this.userform.controls[`Name`].value;
    this.adduserform.Dob = moment(this.userform.controls[`Dob`].value).format("DD-MM-YYYY");
    this.adduserform.EmailID = this.userform.controls[`EmailID`].value;
    this.adduserform.MobileNo = this.userform.controls[`MobileNo`].value;
    this.adduserform.ClubID = this.userform.controls[`ClubID`].value;
    this.adduserform.Password = (this.UserObjID === '') ? this.userform.controls[`MobileNo`].value : this.Password;
    // this.serviceProvider.createUser(this.UserObjID, this.adduserform).subscribe(result => {
      if ((this.adduserform.ClubID !== '') && (this.addcounterid !== '')) {
        this.serviceProvider.updateavailableslot(this.adduserform.ClubID).subscribe(res => {
          if(res === 'Filled'){
            this.toaster.error("Slots Filled. Please Choose Any other Preferences");
          } else {
            this.serviceProvider.addavailableslot(this.addcounterid).subscribe(res => {
              this.serviceProvider.updatepreferencebyId(this.UserObjID, this.adduserform).subscribe(result => {
                this.toaster.success("Submitted Successfully");
                $('#confirmationmodal').modal('hide');
                this.UserObjID = '';
                window.location.reload();
              });
            });
          }
        });
      }
      if ((this.adduserform.ClubID !== '') && (this.addcounterid === '')) {
        if(this.Club !== this.adduserform.ClubID){
          this.serviceProvider.updateavailableslot(this.adduserform.ClubID).subscribe(res => {
            if(res === 'Filled'){
              this.toaster.error("Slots Filled. Please Choose Any other Preferences");
              $('#confirmationmodal').modal('hide');
              this.UserObjID = '';
              window.location.reload();
            } else {
              this.serviceProvider.updatepreferencebyId(this.UserObjID, this.adduserform).subscribe(result => {
                this.toaster.success("Submitted Successfully");
                $('#confirmationmodal').modal('hide');
                this.UserObjID = '';
                window.location.reload();
              });
            }
          });
        } else {
          this.serviceProvider.updatepreferencebyId(this.UserObjID, this.adduserform).subscribe(result => {
            this.toaster.success("Submitted Successfully");
            $('#confirmationmodal').modal('hide');
            this.UserObjID = '';
            window.location.reload();
          });
        }
      }
      if ((this.adduserform.ClubID === '') && (this.addcounterid !== '')) {
        this.serviceProvider.addavailableslot(this.addcounterid).subscribe(res => {
          this.serviceProvider.updatepreferencebyId(this.UserObjID, this.adduserform).subscribe(result => {
            this.toaster.success("Submitted Successfully");
            $('#confirmationmodal').modal('hide');
            this.UserObjID = '';
            window.location.reload();
          });
        });
      }
      if ((this.adduserform.ClubID === '') && (this.addcounterid === '')) {
        this.serviceProvider.updatepreferencebyId(this.UserObjID, this.adduserform).subscribe(result => {
          this.toaster.success("Submitted Successfully");
          $('#confirmationmodal').modal('hide');
          this.UserObjID = '';
          window.location.reload();
        });
      }
    // });
  }
  // File Uploads
  fileSelectEvent(event, id) {
    this.currentId = id;
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.selectedFile = fileList[0];
      const size = this.selectedFile.size;
      const name = this.selectedFile.name;
      const split = name.split('.');
      const fileType = split[1];
      if (this.currentId === 1) {
        if (size < 2000000) {
          const formdata = new FormData();
          const profile_picturefilename = 'profile_picture';
          formdata.append('avatar', this.selectedFile, `${this.UserObjID}_` + profile_picturefilename + `.${fileType}`);
          this.profile_picturefiles = formdata;
          this.profile_picturefileSize = true;
        } else {
          this.selectedFile = null;
          this.profile_picturefileSize = false;
        }
      } else {
        //   alert('No file selected');
      }
    }
  }
  fileNameSave(fileName) {
    if (this.currentId === 1) {
      this.adduserform.ProfileImage = fileName;
    } 
  }
  // Profile Picture 
  profilepicturefilesave() {
    this.serviceProvider.uploadFile(this.UserObjID, this.profile_picturefiles)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            const fileName = event.body;
            this.fileNameSave(fileName);
            const profile_picture = fileName;
            this.adduserform.ProfileImage = profile_picture;
            // this.Submit();
            setTimeout(() => {
              this.progress = 0;
              this.msg = 'File uploaded successfully!';
            }, 3000);
        }
      });
  }
  profilepictureview() {
    var filename1 = this.profile_picturefilename;
    window.open(filename1);
  } 
  profile_picturedelete() {
    // var data = this.profile_picturefileValue;
    // var pieces = data.split(/[\s/]+/);
    // this.deletedata = pieces[pieces.length - 1];
    this.adduserform.ProfileImage = "";  
    $('#profilepicturemodal').modal();
  }
  deleteprofilepictureConfirm() {
    $('#profilepicturemodal').modal('hide');
    // this.serviceProvider.deletefile(this.adduserform.ProfileImage).subscribe(result => {
    //   this.toaster.warning('File deleted sucessfully');
    // });
    this.adduserform.ProfileImage = "";
    this.Submit();
    this.toaster.warning('Deleted Successfully!');
  }
}


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { AuthGuard } from './auth.guard';
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ServicedeclinedComponent } from './servicedeclined/servicedeclined.component';
import { FooterComponent } from './footer/footer.component';
import { SigninComponent } from './signin/signin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClubsComponent } from './clubs/clubs.component';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { ProfileComponent } from './admin/profile/profile.component';
import { UserdashboardComponent } from './userdashboard/userdashboard.component';
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: `full`, },
  { path: '', component: HeaderComponent, canActivate: [AuthGuard] },
  { path: '', component: FooterComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'servicedeclined', component: ServicedeclinedComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'userdashboard', component: UserdashboardComponent, canActivate: [AuthGuard] },
  { path: 'adminprofile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'user', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'Club', component: ClubsComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'footer', component: FooterComponent },  
  { path: 'reset-password/:id', component: ResetPasswordComponent, canActivate: [AuthGuard] },
  { path: 'Servicedeclined', component: ServicedeclinedComponent },
  { path: '**', component: PagenotfoundComponent },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<section class="DiscoverMore mt-5">
    <div class="container">
        <div class="row mb-2">
            <div class="col-sm-12">
                <img src="../../assets/soho_logo.png" width="300" height="150" class="sohologo"/>
            </div>
        </div>
        <div class="container" style="min-height: 500px;">
            <div class="example-header">
                <mat-form-field>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Type here to Search">
                </mat-form-field>
            </div>
    
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSort>
                <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
                    <td mat-cell *matCellDef="let element">{{element[column]}}</td>
                </ng-container>
    
                <!-- Expanded Content Column -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed' ">
                            <!-- <div class="example-element-diagram">
                                <div class="courseTitle">{{element.Title}}</div>
                                <div class="courseType">{{element.Type}}</div>
                                <div class="courseCode">{{element.Code}}</div>
                                <div class="depart_name">{{element.Department}}</div>
                            </div> -->
                            <div class="objectives">
                                <p [innerHTML]="element.objectives"></p>
                                <span class="objectives-attribution">
                                    <p [innerHTML]="element.Description"></p>
                                    <!-- <a [routerLink]="['/courseview', element._id]"> -- See Details</a> -->
                                </span>
                            </div>
                        </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
    
        </div>
        <!-- <mat-form-field class="example-full-width">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="Filter($event)" placeholder="Ex. Mia">
        </mat-form-field>
        <div class="example-container mat-elevation-z8 mt-4">
            <table mat-table [dataSource]="dataSource">
                Manage Clubs 

                <ng-container matColumnDef="ClubID">
                    <th mat-header-cell *matHeaderCellDef>ClubID</th>
                    <td mat-cell *matCellDef="let element">{{element.ClubID}}</td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay1.length">
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed' ">
                            <div class="example-element-diagram">
                                <div class="courseTitle">{{element.Title}}</div>
                                <div class="courseType">{{element.Type}}</div>
                                <div class="courseCode">{{element.Code}}</div>
                                <div class="depart_name">{{element.Department}}</div>
                            </div>
                            <div class="objectives">
                                <p [innerHTML]="element.objectives"></p>
                                <span class="objectives-attribution">
                                    <a [routerLink]="['/courseview', element._id]"> -- See Details</a></span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ClubName">
                    <th mat-header-cell *matHeaderCellDef>Club Name</th>
                    <td mat-cell *matCellDef="let element">{{element.ClubName}}</td>
                </ng-container>

                <ng-container matColumnDef="TotalSlots">
                    <th mat-header-cell *matHeaderCellDef>Total Slots</th>
                    <td mat-cell *matCellDef="let element">{{element.TotalSlots}}</td>
                </ng-container>

                <ng-container matColumnDef="AvailableSlots">
                    <th mat-header-cell *matHeaderCellDef>Available Slots</th>
                    <td mat-cell *matCellDef="let element">{{element.AvailableSlots}}</td>
                </ng-container>

                <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef>Description</th>
                    <td mat-cell *matCellDef="let element"><p [innerHTML]="element.Description"></p></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </div>
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator> -->
    </div>
</section>
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from "@angular/forms";
import { ArticleService } from "../../service/article.service";
import { DataService } from "../../service/DataService";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as model from '../../model/index';
import { MatPaginator } from '@angular/material/paginator';
declare var $: any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  displayedColumns: String[] = ['ClubID', 'ClubName', 'Total Slots', 'Available Slots', 'Description'];
  dataSource: any;
  Clubform: FormGroup;
  userform: FormGroup;
  adduserform: model.User = {};
  userList: any;
  Name: string;
  ClubId: string;
  UserID: string;
  Dob: string; 
  EmailID: string;
  MobileNo: Number;
  Preferences: string;
  ClubName: string;
  AvailableSlots: string;
  TotalSlots: string;
  Description: string;
  PreferencesList: any = [];

  @ViewChild('paginator') paginator: MatPaginator;
  constructor(private dataService: DataService, private toaster: ToastrService, private serviceProvider: ArticleService, private router: Router) { }

  ngOnInit(): void {
    this.Name = JSON.parse(localStorage.getItem('currentUserName'));
    var id = JSON.parse(localStorage.getItem('id'));
    this.addformControl(null);
    this.getUser(id);
    this.getClub();
  }

  addformControl(data){
    this.Clubform = new FormGroup({
      Preferences: new FormControl(''),
    });
  }

  getUser(id) {
    this.serviceProvider.getsingleuser(id).subscribe(result => {
      this.Name = result.Name;
      this.UserID = result.UserID;
      this.Dob = result.Dob;
      this.EmailID = result.UserID;
      this.MobileNo = result.UserID;
      this.serviceProvider.getClubByID(result.ClubID).subscribe(res=>{
        this.ClubId = res[0].ClubID;
        this.Preferences = (result.ClubID === '') ? '' : res[0].ClubName;
        this.ClubName = res[0].ClubName;
        this.AvailableSlots = res[0].AvailableSlots;
        this.TotalSlots = res[0].TotalSlots;
        this.Description = res[0].Description;
      });
    });
  }

  getClub() {
    this.serviceProvider.getClub().subscribe(result=>{
      for(const temp of result){
        if(temp.AvailableSlots > 0){
          this.PreferencesList.push(temp);
        }
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as model from "../model/index";
import { ToastrService } from 'ngx-toastr';
import { ArticleService } from '../service/article.service'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm : FormGroup;
  public resetPassword : model.User = {}
  public resetPasswordId: any;

  constructor(private router: Router, private toastr: ToastrService, private serviceProvider: ArticleService,
     private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.addFormControl();
    this.route.params.subscribe(params => {
      this.resetPasswordId = params.id;
    });
  }

  addFormControl(){
    this.resetPasswordForm = new FormGroup({
      newPassword: new FormControl('', Validators.required),
      reEnterPassword: new FormControl('', Validators.required)
    });
  }

    submit(){
      if(this.resetPasswordForm.controls[`newPassword`].value === this.resetPasswordForm.controls[`reEnterPassword`].value) {
        this.resetPassword.Password = this.resetPasswordForm.controls[`newPassword`].value;
        this.serviceProvider.Updatepassword(this.resetPasswordId,this.resetPassword).subscribe(result =>{
          this.toastr.success('Password changed successfully');
          this.router.navigate(['/signin']);
        });        
      } else {
        this.toastr.warning('Password does not match!!');
      }
      
    }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validator, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as model from '../model/index';
import { ArticleService } from '../service/article.service';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment'
import { filter } from 'rxjs/operators';
import { DataService } from '../service/DataService';
import * as moment from "moment";
declare var $: any;
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
  providers: [DataService]
})
export class SigninComponent implements OnInit {

  loginForm: FormGroup;
  registerForm: FormGroup;
  passwordWorng: boolean;
  emailWrong: boolean;
  noDataFound: boolean;
  severError: boolean;  
  employeeshow: boolean = false;
  employeepasswordshow: boolean = false;
  studentshow: boolean = false;
  studentpasswordshow: boolean = false;
  alumnishow: boolean = false;
  alumnipasswordshow: boolean = false;
  url = environment.url;
  logo: string;
  email: string;
  usertype: string;
  User: string;
  name: string;
  program: string;
  yearofgraduation: string;
  dob: string;
  EmployeeId: string;
  StudentId: string;
  alumniid: string;
  Employeelist: any = [];
  Studentlist: any = [];
  userlist: any = [];
  passObjects : any = [];
  constructor(private serviceProvider: ArticleService, private dataService: DataService, private toastr: ToastrService ,private router: Router) { }

  ngOnInit(): void {
    this.logo = "../../assets/soho_logo.png";
    this.addFormControl();
    this.serviceProvider.logout();
  }  
  addFormControl() {
    this.loginForm = new FormGroup({
      UserID: new FormControl('', Validators.required),
      Password: new FormControl('', Validators.required)
    });
  }
  
  login() {
    const UserID = this.loginForm.controls[`UserID`].value;
    const dateString = this.loginForm.controls[`Password`].value.toString();
    const year = +dateString.substr(4, 4);
    const month = (+dateString.substr(2, 2)).toString().padStart(2, '0');
    const day = (+dateString.substr(0, 2)).toString().padStart(2, '0');
    const Password = day + "-" + month + "-" + year;
    this.serviceProvider.signin(UserID, Password).subscribe({
      next: (result) => {
        var currentdate = new Date();
        if(result.message !== "Email not exist!"){
          if (result.message === 'Logged in successfully!') {
            this.severError = false;
            localStorage.setItem('currentUserName', JSON.stringify(result.Name) );
            localStorage.setItem('id', JSON.stringify(result.id) );
            localStorage.setItem('token', JSON.stringify(result.token) );
            localStorage.setItem('logintime', JSON.stringify(currentdate) );
            if(result.Name === 'admin') {
              window.location.replace(`${this.url}/dashboard`);
            } else {
              window.location.replace(`${this.url}/userdashboard`);
            } 
            this.toastr.success('Logged in successfully!');
          } else if (result.message === 'Incorrect password') {
            this.toastr.error('Incorrect password');
            this.passwordWorng = true;
            this.noDataFound = false;
            this.severError = false;
          } else {
            this.toastr.error('Email not exist!');
            this.emailWrong = true;
            this.passwordWorng = true;
            this.noDataFound = false;
            this.severError = false;
            // alert('check email');
          }
        } else {
          this.toastr.error('Invalid Credentials');
          this.emailWrong = true;
          this.passwordWorng = true;
          this.noDataFound = false;
          this.severError = false;
        }
      }, error: (e) => console.error(e) });
  }
  
  passwordUpdate(){
    // console.log('working')
    $('#forgotPassword').modal('show');
  }

  submit(){
    this.serviceProvider.getuser().subscribe({
      next: (result) => {
      const temp = result.filter(Object => Object.email == this.email)
      if(temp.length === 0){
        this.toastr.error('Email does not Exist');
      }
      else{
        const id = temp[0]._id;
        this.serviceProvider.getEmailById(id).subscribe({
          next: (result) => {
          this.toastr.success('Link send in your Email');
        }, error: (e) => console.error(e) });
        $('#forgotPassword').modal('hide');
      }
    }, error: (e) => console.error(e) });
  }

  passwordupdate(){
    var newpassword = this.registerForm.controls[`newpassword`].value;
    this.userlist.password = this.registerForm.controls[`changepassword`].value;
    if(newpassword === this.userlist.password){
      this.serviceProvider.Updatepassword(this.userlist,this.alumniid).subscribe(result=>{
        this.toastr.success("Thank you for registering. Please signin to login to you Alumni Portal.");
      });
    } else {
      this.toastr.warning("Password Doesnot Match");
    }
  }
}
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { min } from 'moment';
import { convertToObject, couldStartTrivia } from 'typescript';
import { Console } from 'console';

@Injectable()
export class ArticleService {
  baseUri = environment.baseUri;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient) {
  }

  private loggedIns: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isLoggedIn() {
    return this.loggedIns.asObservable();
  }

  header() {
    return new HttpHeaders().set('Content-Type', 'application/json')
      .set('x-access-token', JSON.parse(localStorage.getItem("token")));
    //  this.loggedIn.next(true);
  }

  emailLink(id, data): Observable<any> {
    const url = `${this.baseUri}/sendmail/${id}`;
    return this.http.post(url, data)
      .pipe(
        catchError(this.errorMgmt)
      );
  }

  emailLinkpayment(id, data): Observable<any> {
    const url = `${this.baseUri}/sendmailpayment/${id}`;
    return this.http.post(url, data)
      .pipe(
        catchError(this.errorMgmt)
      );
  }

  // signin
  signin(UserID: string, Password: string): Observable<any> {
    const url = `${this.baseUri}/Users/signin`;
    return this.http.post(url, { UserID, Password })
      .pipe(
        catchError(this.errorMgmt)
      );
  }

  loggedin() {
    if (!!localStorage.getItem('token')) {
      this.loggedIns.next(true);
      return true;
    } else {
      this.loggedIns.next(false);
      return false;
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.clear();
    sessionStorage.clear();
    this.loggedIns.next(false);
  }

  // Error handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);    
  }

  getClub(): Observable<any> {
    return this.http.get(`${this.baseUri}/Clubs`);
  }

  getClubByID(id: any): Observable<any> {
    return this.http.get(`${this.baseUri}/Clubs/${id}`);
  }

  deleteClub(id: any): Observable<any> {
    const url = `${this.baseUri}/Clubs/` + id;
    return this.http.delete(url, { headers: this.headers }).pipe(
      catchError(this.errorMgmt)
    );
  }

  //create  Club
  createClub(id, data): Observable<any> {
    if (!!id) {
      const url = `${this.baseUri}/Clubs/${id}`;
      return this.http.put(url, data).pipe(catchError(this.errorMgmt));
    }
    else {
      const url = `${this.baseUri}/Clubs/`;
      return this.http.post(url, data).pipe(catchError(this.errorMgmt));
    }
  }

  updatepreferencebyId(id, data): Observable<any> {
    const url = `${this.baseUri}/Users/updatepreference/${id}`;
    return this.http.put(url, data).pipe(catchError(this.errorMgmt));
  }

  updateavailableslot(id: any): Observable<any> {
    const url = `${this.baseUri}/Clubs/counter/${id}`;
    return this.http.post(url, { headers: this.headers }).pipe(
      catchError(this.errorMgmt)
    );
  }

  addavailableslot(id: any): Observable<any> {
    const url = `${this.baseUri}/Clubs/addcounter/${id}`;
    return this.http.post(url, { headers: this.headers }).pipe(
      catchError(this.errorMgmt)
    );
  }

  // Create User
  createUser(id, data): Observable<any> {
    if (!!id) {
      const url = `${this.baseUri}/Users/${id}`;
      return this.http.put(url, data).pipe(catchError(this.errorMgmt));
    }
    else {
      const url = `${this.baseUri}/Users/`;
      return this.http.post(url, data).pipe(catchError(this.errorMgmt));
    }
  }

  // get user
  getuser(): Observable<any> {
    return this.http.get(`${this.baseUri}/Users/`);
  }

  getsingleuser(id: any): Observable<any> {
    return this.http.get(`${this.baseUri}/Users/${id}`);
  }

  getUserByID(id): Observable<any> {
    var data = { id };
    const url = `${this.baseUri}/Users/UserByID/`;
    return this.http.post(url, data).pipe(catchError(this.errorMgmt));
  }

  deleteUser(id: any): Observable<any> {
    const url = `${this.baseUri}/Users/` + id;
    return this.http.delete(url, { headers: this.headers }).pipe(
      catchError(this.errorMgmt)
    );
  }

   // upload file
   uploadFile(id, data): Observable<any> {
    const url = `${this.baseUri}/Users/upload`;
    return this.http.post(url, data, {
      reportProgress: true,
      observe: 'events'
    })
      .pipe(
        catchError(this.errorMgmt)
      );
  }

  deletefile(imagefile): Observable<any> {
    const url = `${this.baseUri}/Users/v1/deletefile/` + imagefile;
    return this.http.delete<any>(url, { headers: this.headers })
      .pipe(
        catchError(this.errorMgmt)
      );
  }
  
  getClubUsers(id: any): Observable<any> {
    var data = { id };
    const url = `${this.baseUri}/Users/Clubusers`;
    return this.http.post(url, data, { headers: this.headers }).pipe(
      catchError(this.errorMgmt)
    );
  }

  // get email for forgot password
  getEmailById(id): Observable<any> {
    const url = `${this.baseUri}/Users/getUserId/${id}`;
    return this.http.get(url, id)
      .pipe(
        catchError(this.errorMgmt)
      );
  }

  // ContactUs
  createcontactus(data: any): Observable<any> {
    const url = `${this.baseUri}/contactus`;
    return this.http.post(url, data)
      .pipe(
        catchError(this.errorMgmt)
      );
  }
  // update password 
  Updatepassword(id, data): Observable<any> {
    const url = `${this.baseUri}/Users/updatepassword/${id}`;
    return this.http.put(url, data).pipe(catchError(this.errorMgmt));
  }

}




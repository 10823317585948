<div class="app-body" style="width: 100%;">
  <main class="main d-flex align-items-center">
    <div class="container">
      <br /> <br />
      <div class="row">
        <div class="col-md-3 p0" style="margin:0 auto">
        </div>
        <div class="col-md-6 p0" style="margin:0 auto">
          <div class="card-group">
            <div class="card ">
              <div style=" border-bottom: 2px dashed #bbb;text-align:center;padding: 20px 0px;">
                <img src="{{logo}}" class="img-responsive brand-img" style="width: 250px;height: 150px;">
              </div>
              <div class="card-body form_ctr">
                <form [formGroup]="loginForm">
                  <h1 class="text-center">Login</h1>
                  <p class="text-muted text-center">Sign In to your account</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-fw fa-phone"></i></span>
                    </div>
                    <input type="text" name="UserID" class="form-control" formControlName="UserID"
                      placeholder="Mobile No" autocomplete="UserID" required>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-fw fa-lock"></i></span>
                    </div>
                    <input type="password" name="Password" class="form-control" formControlName="Password"
                      placeholder="Password" autocomplete="current-password" required>
                  </div>
                  <div class="row">
                    <div class="col-12 text-right">
                      <button type="button" class="btn btn-primary px-4" (click)="login()">Login</button>
                    </div>
                    <!-- <div class="col-6 text-right">
                      <button type="button" class="btn btn-link px-0" (click)="passwordUpdate()">Forgot
                        password?</button>
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 p0" style="margin:0 auto">
        </div>
      </div>
    </div>
  </main>
</div>
<div class="modal fade" id="forgotPassword" role="dialog">
  <div class="modal-dialog  col-md-6">
    <!-- Modal content-->
    <div class="modal-content">

      <div class="modal-header">
        <h2 class="modal-title">Forgot Password?</h2>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div style="margin: 15px;">
        <h4 class="modal-title">A password reset link will be sent to your email to reset your password.</h4>
      </div>
      <div class="modal-body">
        <mat-form-field class="example-full-width">
          <mat-label>User Type</mat-label>
          <mat-select [(ngModel)]="usertype" name="usertype">
            <mat-option value="Employee"> Employee</mat-option>
            <mat-option value="Student"> Student</mat-option>
            <mat-option value="Alumni"> Alumni</mat-option>
            <mat-option value="Admin"> Admin</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Email address</mat-label>
          <input [(ngModel)]="email" type="email" matInput placeholder="Enter your email">
        </mat-form-field>
      </div>
      <!-- <div class="modal-footer"> -->
      <div class="row">
        <div class="col-md-6" style="text-align: center;">
          <button type="button" class="btn btn-primary" (click)="submit()">Submit</button>
        </div>
        <div class="col-md-6" style="text-align: center;">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>
      </div>
      <br>
    </div>
  </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from "@angular/forms";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import * as moment from "moment";
import { ArticleService } from "../service/article.service";
import { ExcelService } from '../service/excel-service';
import { DataService } from "../service/DataService";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as model from '../model/index';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
declare var $: any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  userform: FormGroup;
  adduserform: model.User = {};
  userobjid: string = '';
  UserID: string;
  Password: string;
  Club: string = '';
  displayedColumns: String[] = ['Name', 'Dob', 'EmailID', 'MobileNo', 'ClubName', 'edit', 'delete'];
  dataSource: any;
  userList: any = [];
  Clublist: any = [];
  addcounterid: string = '';
  
  ProfileImage: string;
  // Profile Picture
  public profile_picturefiles: any = [];
  public profile_picture: string;
  public profile_pictureFile: Array<{ name: string, caption: string }> = [];
  public profile_picturefileSize = true;
  public profile_picturefileValue: string;
  public profile_picturefilename: string;
  
  // File Upload   
  public deleteIndex: number;
  public deleteId: string;
  public deletedata: any = [];
  private selectedFile: File = null;
  private currentId: number;  
  public progress = 0;
  public msg: string;

  @ViewChild('paginator') paginator: MatPaginator;
  constructor(private dataService: DataService, private excelService: ExcelService, private toaster: ToastrService, private serviceProvider: ArticleService, private router: Router) { }

  ngOnInit(): void {
    this.addformControl(null);
    this.getUsers();
    this.getClub();
  }

  addformControl(data) {
    if (data === null) {
      this.userform = new FormGroup({
        UserID: new FormControl(''),
        Name: new FormControl('', Validators.required),
        Dob: new FormControl(moment(), Validators.required),
        EmailID: new FormControl('', [Validators.required, Validators.email]),
        MobileNo: new FormControl('', [
          Validators.required,
          Validators.min(10),
          Validators.max(10),
        ]),
        ClubID: new FormControl(''),
        profile_picture: new FormControl(''),
      });
      this.userobjid = '';
      this.ProfileImage = '';
      this.profile_picturefilename = '';
    } else {
      this.userform = new FormGroup({
        UserID: new FormControl(data.UserID),
        Name: new FormControl(data.Name),
        Dob: new FormControl(data.Dob, [
          Validators.required,
          Validators.min(10),
          Validators.max(10)
        ]),
        EmailID: new FormControl(data.EmailID, [Validators.required, Validators.email]),
        MobileNo: new FormControl(data.MobileNo),
        ClubID: new FormControl(data.ClubName),
        profile_picture: new FormControl(data.ProfileImage),
      });
      this.userobjid = data._id;
      this.profile_picturefilename = data.ProfileImage;
      this.adduserform.ProfileImage = data.ProfileImage;
    }
  }

  getUsers() {
    this.serviceProvider.getuser().subscribe({
      next: (result) => {
      if (result) {
        for (const temp of result) {
          this.serviceProvider.getClubByID(temp.ClubID).subscribe(Clubresult => {
            this.userList.push({
              _id: temp._id,
              UserID: temp.UserID,
              Name: temp.Name,
              Dob: temp.Dob,
              EmailID: temp.EmailID,
              MobileNo: temp.MobileNo,
              ProfileImage: temp.ProfileImage,
              ClubName: (temp.ClubID === Clubresult[0]._id) ? Clubresult[0].ClubName : '',
            });
            this.dataSource = new MatTableDataSource<any>(this.userList);
            this.dataSource.paginator = this.paginator;
          });
        }
      } else {
        this.toaster.error("No Records Found");
      }
    }, error: (e) => console.error(e) });
  }

  getClub() {
    this.serviceProvider.getClub().subscribe(result => {
      for(const temp of result){
        if(temp.AvailableSlots > 0){
          this.Clublist.push(temp);
        }
      }
    });
  }

  adduser() {
    this.addformControl(null);
    $('#usermodal').modal("show");
  }

  edituser(data, id) {
    if (data.UserID === 'admin') {
      this.userobjid = id;
      this.serviceProvider.getUserByID(this.userobjid).subscribe(result => {
        if (result.length !== 0) {
          this.addformControl(result[0]);
          this.Password = result[0].Password;
          this.userform.controls[`UserID`].setValue(result[0].UserID);
          this.userform.controls[`Name`].setValue(result[0].Name);
          this.userform.controls[`Dob`].setValue(moment(result[0].Dob).format("YYYY-MM-DD"));
          this.userform.controls[`EmailID`].setValue(result[0].EmailID);
          this.userform.controls[`MobileNo`].setValue(result[0].MobileNo);
          this.userform.controls[`ClubID`].setValue(result[0].ClubID);
          this.userform.controls[`profile_picture`].setValue(result.ProfileImage);
          this.Club = result[0].ClubID;
          $('#usermodal').modal("show");
        } else {
          this.serviceProvider.getsingleuser(this.userobjid).subscribe(result => {
            this.addformControl(result);
            this.Password = result.Password;
            this.userform.controls[`UserID`].setValue(result.UserID);
            this.userform.controls[`Name`].setValue(result.Name);
            this.userform.controls[`Dob`].setValue(moment(result.Dob).format("YYYY-MM-DD"));
            this.userform.controls[`EmailID`].setValue(result.EmailID);
            this.userform.controls[`MobileNo`].setValue(result.MobileNo);
            this.userform.controls[`ClubID`].setValue(result.ClubID);
            this.userform.controls[`profile_picture`].setValue(result.ProfileImage);
            this.Club = result.ClubID;
            $('#usermodal').modal("show");
          });
        }
      });
    } else {
      this.userobjid = id;
      this.serviceProvider.getsingleuser(this.userobjid).subscribe(result => {
        this.addformControl(result);
        this.Password = result.Password;
        this.userform.controls[`UserID`].setValue(result.UserID);
        this.userform.controls[`Name`].setValue(result.Name);
        this.userform.controls[`Dob`].setValue(moment(result.Dob).format("YYYY-MM-DD"));
        this.userform.controls[`EmailID`].setValue(result.EmailID);
        this.userform.controls[`MobileNo`].setValue(result.MobileNo);
        this.userform.controls[`ClubID`].setValue(result.ClubID);
        this.userform.controls[`profile_picture`].setValue(result.ProfileImage);
        this.Club = result.ClubID;
        $('#usermodal').modal("show");
      });
    }
  }

  deleteuser(id) {
    this.userobjid = id;
    $('#deleteusermodal').modal('show');
  }

  deleteConfirm() {
    $('#deleteusermodal').modal('hide');
    this.serviceProvider.deleteUser(this.userobjid).subscribe(result => {
      this.toaster.success("Deleted Successfully");
    });
    window.location.reload();
    this.getUsers();
  }

  changepreference(data) {
    if (this.userobjid !== '') {
      this.serviceProvider.getsingleuser(this.userobjid).subscribe(result => {
        this.addcounterid = result.ClubID;
      });
    }
  }

  Submit() {
    $('#confirmationmodal').modal('show');
  }

  Confirm() {
    this.adduserform.UserID = this.userform.controls[`UserID`].value;
    this.adduserform.Name = this.userform.controls[`Name`].value;
    this.adduserform.Dob = moment(this.userform.controls[`Dob`].value).format("DD-MM-YYYY");
    this.adduserform.EmailID = this.userform.controls[`EmailID`].value;
    this.adduserform.MobileNo = this.userform.controls[`MobileNo`].value;
    this.adduserform.ClubID = this.userform.controls[`ClubID`].value;
    // this.adduserform.Password = (this.userobjid === '') ? moment(this.userform.controls[`Dob`].value).format("DD-MM-YYYY") : this.Password;
    this.serviceProvider.createUser(this.userobjid, this.adduserform).subscribe(result => {
      if((this.adduserform.ClubID !== '') && (this.addcounterid !== '')){       
        this.serviceProvider.updateavailableslot(this.adduserform.ClubID).subscribe(res => {
          if(res === 'Filled'){
            this.toaster.error("Slots Filled. Please Choose Any other Preferences");
          } else {
            this.serviceProvider.addavailableslot(this.addcounterid).subscribe(res => {
              this.serviceProvider.updatepreferencebyId(this.userobjid, this.adduserform).subscribe(result => {
                this.toaster.success("Submitted Successfully");
                $('#confirmationmodal').modal('hide');
                this.userobjid = '';
                window.location.reload();
              });
            });
          }
        });
      } 
      if((this.adduserform.ClubID !== '') && (this.addcounterid === '')){   
        if(this.Club !== this.adduserform.ClubID){
          this.serviceProvider.updateavailableslot(this.adduserform.ClubID).subscribe(res=>{ 
            if(res === 'Filled'){
              this.toaster.error("Slots Filled. Please Choose Any other Preferences");
              $('#confirmationmodal').modal('hide');
              this.userobjid = '';
              window.location.reload();
            } else {       
              this.serviceProvider.updatepreferencebyId(this.userobjid, this.adduserform).subscribe(result => {
                this.toaster.success("Submitted Successfully");
                $('#confirmationmodal').modal('hide');
                this.userobjid = '';
                window.location.reload();
              });
            }
          });
        } else {
          this.serviceProvider.updatepreferencebyId(this.userobjid, this.adduserform).subscribe(result => {
            this.toaster.success("Submitted Successfully");
            $('#confirmationmodal').modal('hide');
            this.userobjid = '';
            window.location.reload();
          });
        }
      } 
      if((this.adduserform.ClubID === '') && (this.addcounterid !== '')){          
        this.serviceProvider.addavailableslot(this.addcounterid).subscribe(res=>{  
          this.serviceProvider.updatepreferencebyId(this.userobjid, this.adduserform).subscribe(result => {
            this.toaster.success("Submitted Successfully");
            $('#confirmationmodal').modal('hide');
            this.userobjid = '';
            window.location.reload();
          });
        });
      } 
      if((this.adduserform.ClubID === '') && (this.addcounterid === '')){    
        this.serviceProvider.updatepreferencebyId(this.userobjid, this.adduserform).subscribe(result => {
          this.toaster.success("Submitted Successfully");
          $('#confirmationmodal').modal('hide');
          this.userobjid = '';
          window.location.reload();  
        });   
      }    
    });
  }

  Export() {
    this.excelService.exportAsExcelFile(this.userList, 'Users Report');
  }

  
  // File Uploads
  fileSelectEvent(event, id) {
    this.currentId = id;
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.selectedFile = fileList[0];
      const size = this.selectedFile.size;
      const name = this.selectedFile.name;
      const split = name.split('.');
      const fileType = split[1];
      if (this.currentId === 1) {
        if (size < 2000000) {
          const formdata = new FormData();
          const profile_picturefilename = 'profile_picture';
          formdata.append('avatar', this.selectedFile, `${this.userobjid}_` + profile_picturefilename + `.${fileType}`);
          this.profile_picturefiles = formdata;
          this.profile_picturefileSize = true;
        } else {
          this.selectedFile = null;
          this.profile_picturefileSize = false;
        }
      } else {
        //   alert('No file selected');
      }
    }
  }
  fileNameSave(fileName) {
    if (this.currentId === 1) {
      this.adduserform.ProfileImage = fileName;
    }
  }
  // Profile Picture 
  profilepicturefilesave() {
    this.serviceProvider.uploadFile(this.userobjid, this.profile_picturefiles)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            const fileName = event.body;
            this.fileNameSave(fileName);
            const profile_picture = fileName;
            this.adduserform.ProfileImage = profile_picture;
            // this.Submit();
            setTimeout(() => {
              this.progress = 0;
              this.msg = 'File uploaded successfully!';
            }, 3000);
        }
      });
  }
  profilepictureview() {
    var filename1 = this.profile_picturefilename;
    window.open(filename1);
  } 
  profile_picturedelete() {
    // var data = this.profile_picturefileValue;
    // var pieces = data.split(/[\s/]+/);
    // this.deletedata = pieces[pieces.length - 1];
    this.adduserform.ProfileImage = "";
    $('#profilepicturemodal').modal();
  }
  deleteprofilepictureConfirm() {
    $('#profilepicturemodal').modal('hide');
    // this.serviceProvider.deletefile(this.deletedata).subscribe(result => {
    //   this.toaster.warning('File deleted sucessfully');
    // });
    this.adduserform.ProfileImage = "";
    this.Submit();
    this.toaster.warning('Deleted Successfully!');
  }
}

<div id="wrapper-section">
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <h2 style='margin-bottom:0px;padding: 15px;background-color: #fff;'>Manage Clubs
                    <button mat-raised-button color="primary" class="pull-right" (click)="addClub()">
                        <i class="fa fa-fw fa-plus"></i>Add Club
                    </button>
                </h2>
                <div class="example-container mat-elevation-z8 mt-4">
                    <table mat-table [dataSource]="dataSource">
                        <!-- Manage Clubs  -->

                        <ng-container matColumnDef="ClubID">
                            <th mat-header-cell *matHeaderCellDef>ClubID</th>
                            <td mat-cell *matCellDef="let element">{{element.ClubID}}</td>
                        </ng-container>

                        <ng-container matColumnDef="ClubName">
                            <th mat-header-cell *matHeaderCellDef>Club Name</th>
                            <td mat-cell *matCellDef="let element">{{element.ClubName}}</td>
                        </ng-container>

                        <ng-container matColumnDef="TotalSlots">
                            <th mat-header-cell *matHeaderCellDef>Total Slots</th>
                            <td mat-cell *matCellDef="let element">{{element.TotalSlots}}</td>
                        </ng-container>

                        <ng-container matColumnDef="AvailableSlots">
                            <th mat-header-cell *matHeaderCellDef>Available Slots</th>
                            <td mat-cell *matCellDef="let element">{{element.AvailableSlots}}</td>
                        </ng-container>

                        <ng-container matColumnDef="View">
                            <th mat-header-cell *matHeaderCellDef>View</th>
                            <td mat-cell *matCellDef="let element"><button mat-raised-button class="mx-3 my-1 small_btn"
                                    color="accent" (click)="ViewClubusers(element._id)">View</button></td>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef>Edit</th>
                            <td mat-cell *matCellDef="let element"><button mat-raised-button class="mx-3 my-1 small_btn"
                                    color="primary" (click)="editClub(element._id)">Edit</button></td>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef>Delete</th>
                            <td mat-cell *matCellDef="let element"><button mat-raised-button class="mx-3 my-1 small_btn"
                                    color="warn" [disabled]="true" (click)="deleteClub(element._id)">Delete</button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                </div>
                <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>
<div id="Clubmodal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-fw fa-plus-circle"></i>
                </div>
                <h4 class="modal-title" style="color:green;"><span *ngIf="ClubID === ''">ADD</span><span
                        *ngIf="ClubID !== ''">EDIT</span> Club</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="Clubform">
                    <div class="row">
                        <mat-form-field class="example-full-width" name="ClubID" class="col-md-4">
                            <input type="text" matInput placeholder="Club ID" formControlName="ClubID">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="ClubName" class="col-md-4">
                            <input type="text" matInput placeholder="ClubName" formControlName="ClubName">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="TotalSlots" class="col-md-4">
                            <input type="text" matInput placeholder="Total Slots" [readonly]="!!this.ClubID"
                                formControlName="TotalSlots">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="AvailableSlots" class="col-md-4">
                            <input type="text" matInput placeholder="Available Slots" formControlName="AvailableSlots">
                        </mat-form-field>

                        <div>
                            <quill-editor formControlName="Description">
                            </quill-editor>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
                <button mat-raised-button color="primary" (click)="Submit()"> Submit </button>
            </div>
        </div>
    </div>
</div>

<div id="ViewUsersmodal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-fw fa-list"></i>
                </div>
                <h4 class="modal-title">Club Users List</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <table [cellPadding]="15" [cellSpacing]="15">
                    <thead>
                        <tr>
                            <th>User Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of userslist">
                            <td><i class="fa fa-fw fa-caret-right"></i>{{data.Name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div id="confirmationmodal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-fw fa-times-circle-o"></i>
                </div>
                <h4 class="modal-title">Are you sure?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <p>Do you really want to update these record?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
                <button mat-raised-button color="accent" (click)="Confirm()">Confirm</button>
            </div>
        </div>
    </div>
</div>

<div id="deletemodal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-fw fa-times-circle-o"></i>
                </div>
                <h4 class="modal-title">Are you sure?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <p>Do you really want to delete these records? This process cannot be undone.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" (click)="deleteConfirm()">Delete</button>
            </div>
        </div>
    </div>
</div>
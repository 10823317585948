<div class="container-fluid Footer-Caption" [hidden]="isfooter1">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3>{{footerdesc}}</h3>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid Main-Footer" [hidden]="isfooter1">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <p>Copyrights © 2020. All rights reserved. Designed & Developed by <a href="https://ABC.Com/"
                        style="color: #999999;text-decoration: none;">ABC.Com</a></p>
            </div>
        </div>
    </div>
</div>
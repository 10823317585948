<div id="wrapper-section">
    <div class="container-fluid">
        <h2>Hi, Welcome {{Name}}</h2>
        <hr />
        <!-- <mat-grid-list cols="4" rowHeight="100px">
            <mat-grid-tile *ngFor="let tile of tiles" [colspan]="tile.cols" [rowspan]="tile.rows"
                [style.background]="tile.color">
                {{tile.text}}
            </mat-grid-tile>
        </mat-grid-list> -->
        <mat-grid-list cols="4" rowHeight="400px" style="border: 2px solid green;">
            <mat-grid-tile [colspan]="3" [rowspan]="1">
                <div [@.disabled]="true">
                    <mat-tab-group dynamicHeight>
                        <mat-tab label="Column Chart">
                            <ng-template matTabContent>
                                <canvasjs-chart [options]="columnChartOptions"
                                    [styles]="{ width: '100%', height: '360px' }"></canvasjs-chart>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Pie Chart">
                            <ng-template matTabContent>
                                <canvasjs-chart [options]="pieChartOptions"
                                    [styles]="{ width: '100%', height: '360px' }"></canvasjs-chart>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Line Chart">
                            <ng-template matTabContent>
                                <canvasjs-chart [options]="lineChartOptions"
                                    [styles]="{ width: '100%', height: '360px' }"></canvasjs-chart>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="2">
                abc.com
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="1">
                abc.com
            </mat-grid-tile>
            <mat-grid-tile [colspan]="2" [rowspan]="1">
                <div class="example-container mat-elevation-z8 mt-4">
                    <table mat-table [dataSource]="ClubdataSource">
                        <!-- Manage Clubs  -->
        
                        <ng-container matColumnDef="ClubID">
                            <th mat-header-cell *matHeaderCellDef>ClubID</th>
                            <td mat-cell *matCellDef="let element">{{element.ClubID}}</td>
                        </ng-container>
        
                        <ng-container matColumnDef="ClubName">
                            <th mat-header-cell *matHeaderCellDef>Club Name</th>
                            <td mat-cell *matCellDef="let element">{{element.ClubName}}</td>
                        </ng-container>
        
                        <ng-container matColumnDef="TotalSlots">
                            <th mat-header-cell *matHeaderCellDef>Total Slots</th>
                            <td mat-cell *matCellDef="let element">{{element.TotalSlots}}</td>
                        </ng-container>
        
                        <ng-container matColumnDef="AvailableSlots">
                            <th mat-header-cell *matHeaderCellDef>Available Slots</th>
                            <td mat-cell *matCellDef="let element">{{element.AvailableSlots}}</td>
                        </ng-container>
        
                        <tr mat-header-row *matHeaderRowDef="ClubdisplayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: ClubdisplayedColumns;"></tr>
        
                    </table>
                    <mat-paginator #Clubpaginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
                </div>
            
            </mat-grid-tile>
        </mat-grid-list>
        <hr />
    </div>
</div>
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fi } from 'date-fns/locale';
import { ArticleService } from './service/article.service';

@Injectable()
export class AuthGuard  {
  constructor(private authService: ArticleService, private router: Router) { }

  // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  //   return this.authService.isLoggedIn.pipe(
  //     take(1),
  //     map((isLoggedIn: boolean) => {
  //       if (!isLoggedIn) {
  //         this.router.navigate(['/login']);
  //         return false;
  //       }
  //       return true;
  //     })
  //   );
  // }
  canActivate(): boolean {
    if (this.authService.loggedin()) {
      var id = JSON.parse(localStorage.getItem('id'));
      return true;
    } else {
      this.router.navigate(['/signin']);
      return false;
    }
  }

}

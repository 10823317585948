<div id="wrapper-section">
    <div class="container-fluid">
        <h2>Hi, Welcome {{Name}}</h2>
        <div class="row">
            <table [cellSpacing]="15" [cellPadding]="15">
                <tbody>
                    <tr>
                        <th>Name</th><th>:</th><td>{{Name}}</td>
                    </tr>
                    <tr>
                        <th>UserID</th><th>:</th><td>{{UserID}}</td>
                    </tr>
                    <tr>
                        <th>Dob</th><th>:</th><td>{{Dob}}</td>
                    </tr>
                    <tr>
                        <th>EmailID</th><th>:</th><td>{{EmailID}}</td>
                    </tr>
                    <tr>
                        <th>MobileNo</th><th>:</th><td>{{MobileNo}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr/>
    </div>
</div>
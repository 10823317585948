
import {Injectable} from "@angular/core";
declare let gtag:Function;
@Injectable()
export class GoogleAnalyticsService {

   public eventEmitter( 
       eventName: string, 
       eventClub: string, 
       eventAction: string, 
       eventLabel: string = null,  
       eventValue: number = null ){ 
            gtag('event', eventName, { 
                    eventClub: eventClub, 
                    eventLabel: eventLabel, 
                    eventAction: eventAction, 
                    eventValue: eventValue
                  })
       }
       
       
}
<div id="wrapper-section">
    <div class="container-fluid">
        <mat-tab-group>
            <!-- Basic Information -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>Information</span>
                </ng-template>
                <br/>
                <h2>Hi, Welcome <b>{{Name}}</b>                    
                    <button mat-raised-button color="primary" class="pull-right" (click)="editinfo()">
                        <span *ngIf="!Preferences"> Select Club</span>
                        <span *ngIf="!!Preferences"> Change Preference</span>
                    </button>
                </h2>
                <div class="row">
                    <table [cellSpacing]="15" [cellPadding]="15">
                        <tbody>
                            <!-- <tr *ngIf="!!ProfileImage">
                                <th>Profile Image</th><th>:</th>
                                <td><img src="{{ProfileImage}}" width="150" height="150"/></td>
                            </tr> 
                            <tr>
                                <th>Name</th><th>:</th><td>{{Name}}</td>
                            </tr>
                            <tr>
                                <th>UserID</th><th>:</th><td>{{UserID}}</td>
                            </tr> -->
                            <tr>
                                <th>Dob</th><th>:</th><td>{{Dob}}</td>
                            </tr>
                            <!-- <tr>
                                <th>EmailID</th><th>:</th><td>{{EmailID}}</td>
                            </tr> -->
                            <tr>
                                <th>MobileNo</th><th>:</th><td>{{MobileNo}}</td>
                            </tr>
                            <tr *ngIf="!Preferences">
                                <th>Preferences</th><th>:</th><td>{{Preferences}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row" *ngIf="!!Preferences" style="padding: 15px !important;">
                        <h3 style="margin-left: 15px !important;"><b>Your Selected Club:</b></h3>
                        <table [cellSpacing]="15" [cellPadding]="15">
                            <tbody>
                                <tr>
                                    <th><span class="clubname">{{ClubName}}</span></th>
                                <tr>
                                    <th colspan="6">Description:</th>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <p [innerHTML]="Description"></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr/>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span>Clubs</span>
                </ng-template>
                <br/>
                <div class="example-header">
                    <mat-form-field>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Type here to Search">
                    </mat-form-field>
                </div>
        
                <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSort>
                    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
                        <td mat-cell *matCellDef="let element">{{element[column]}}</td>
                    </ng-container>
        
                    <!-- Expanded Content Column -->
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                            <div class="example-element-detail"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed' ">
                                <div class="objectives">
                                    <p [innerHTML]="element.objectives"></p>
                                    <span class="objectives-attribution">
                                        <p [innerHTML]="element.Description"></p>
                                    </span>
                                </div>
                            </div>
                        </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let element; columns: columnsToDisplay" class="example-element-row"
                        [class.example-expanded-row]="expandedElement === element"
                        (click)="expandedElement = expandedElement === element ? null : element">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
               
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div id="usermodal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-fw fa-plus-circle"></i>
                </div>
                <h4 class="modal-title" style="color:green;">Edit Information</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="userform">
                    <div class="row">
                        <!-- <div class="row" *ngIf="UserObjID !== ''">
                            <div class="col-sm-6" *ngIf="profile_picturefilename === ''">
                                <input type="file" (change)="fileSelectEvent($event,1)"
                                    accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF"
                                    formControlName="profile_picture">
                                <div class="col-md-4">
                                    <span *ngIf="!!profile_picture">File Size: Max
                                        2MB</span>
                                    <span *ngIf="!profile_picture" style="color: red;">File
                                        Size:
                                        Max 2MB</span>
                                </div>
                            </div>
                            <div class="col-sm-6" *ngIf="profile_picturefilename === ''">
                                <button type="button" class="btn btn-primary"
                                    style="position: relative;left: 22px;"
                                    (click)="profilepicturefilesave()">
                                    <i class="fa fa-fw fa-upload"></i> Upload
                                </button>
                            </div>
                            <div class="col-sm-3" *ngIf="profile_picturefilename !== ''">
                                <h3 id="parentdiv">Profile Picture</h3>
                            </div>
                            <div class="col-sm-9" *ngIf="profile_picturefilename !== ''">
                                <img src="{{profile_picturefilename}}" width="100" height="100" />
                                <button type="button" mat-raised-button class="btn btn-primary"
                                    (click)="profilepictureview()">
                                    <i class="fa fa-fw fa-download"></i> Download
                                </button>
                                <button type="button" name="Delete" mat-raised-button color="warn"
                                    (click)="profile_picturedelete()"><i class="fa fa-fw fa-trash"></i>
                                    Delete</button>
                            </div>
                        </div><br/> -->

                        <mat-form-field class="example-full-width" name="ClubID" class="col-md-6">
                            <mat-label>Preference</mat-label>
                            <mat-select formControlName="ClubID" (ngModelChange)="changepreference($event)">
                                <mat-option value="">-Select-</mat-option>
                                <mat-option *ngFor="let data of PreferencesList" value="{{data._id}}">
                                    {{data.ClubName}}</mat-option>
                            </mat-select>
                            <input type="text" matInput placeholder="User ID" style="display: none;"
                            formControlName="UserID">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="Name" class="col-md-6">
                            <input type="text" matInput placeholder="Name"
                                formControlName="Name">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="Dob" class="col-md-6">
                            <mat-label>Date of Birth</mat-label>
                            <input matInput placeholder="DD-MM-YYYY" type="date" formControlName="Dob">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="EmailID" class="col-md-6" style="display: none;">
                            <input type="text" formControlName="EmailID" matInput
                                placeholder="Email ID">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="MobileNo" class="col-md-6">
                            <span matPrefix>+91 &nbsp;</span>
                            <input type="number" formControlName="MobileNo" matInput
                                placeholder="Mobile No">
                        </mat-form-field>
                      
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
                <button mat-raised-button color="primary" (click)="Submit()"> Submit </button>
            </div>
        </div>
    </div>
</div>

<div id="confirmationmodal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-fw fa-times-circle-o"></i>
                </div>
                <h4 class="modal-title">Are you sure?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <p>Do you really want to update these record?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
                <button mat-raised-button color="accent" (click)="Confirm()">Confirm</button>
            </div>
        </div>
    </div>
</div>
<div id="profilepicturemodal" class="modal fade delete-twelveb">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="material-icons">&#xE5CD;</i>
                </div>
                <h4 class="modal-title">Are you sure?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <p>Do you really want to delete these records? This process cannot be undone.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" (click)="deleteprofilepictureConfirm()">Delete</button>
            </div>
        </div>
    </div>
</div>
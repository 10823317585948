import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArticleService } from '../service/article.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { animate, state, style, trigger, transition } from "@angular/animations";
import { MatSort } from "@angular/material/sort";
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('detailExpand',
    [
        state('collapsed, void', style({ height: '0px'})),
        state('expanded', style({ height: '*' })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class HomeComponent implements OnInit {
  public ClubList: any = [];
  public dataSource: any = [];
  columnsToDisplay : string[] = ['ClubName', 'AvailableSlots'];
  expandedElement : any | null;
  @ViewChild('paginator') paginator: MatPaginator;
  constructor(private serviceProvider: ArticleService, private toastr: ToastrService, private router: Router) {
    localStorage.removeItem('token');
   }
  public url = environment.url;
  ngOnInit(): void {
    this.getClub();
  }  
  getClub(){
    this.serviceProvider.getClub().subscribe(result=>{
      this.ClubList = result;
      this.dataSource = new MatTableDataSource<any>(this.ClubList);
      setTimeout(() => this.dataSource.paginator = this.paginator);
    });
  }
  // Filter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  //   this.dataSource.filter = filterValue;
  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }
  
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}

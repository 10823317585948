
<br>
<div class="container">    
    <div class="col-md-6 offset-3">
        <form [formGroup]="resetPasswordForm" class="example-form LogForm">
            <h3>Reset Password</h3>
            <mat-form-field class="example-full-width">
                <input type="password" formControlName="newPassword" matInput placeholder="New Password" required>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <input type="password" formControlName="reEnterPassword" matInput placeholder="Re-enter the Password">
            </mat-form-field>
            <div class="row">
                <div class="col-md-6">
                    <button class="btn btn-primary" [disabled]="!resetPasswordForm.valid" (click)="submit()" >Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-servicedeclined',
  templateUrl: './servicedeclined.component.html',
  styleUrls: ['./servicedeclined.component.css']
})
export class ServicedeclinedComponent implements OnInit {

  constructor(private router: Router,) { }

  ngOnInit(): void {
    var path = location.pathname;
    var submodulerights = sessionStorage.getItem('submodulerights').split(",");
    if(submodulerights.indexOf(path) === -1){
      this.router.navigate(['/signin']);
    }
  }

}

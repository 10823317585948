import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from "@angular/forms";
import { ArticleService } from "../service/article.service";
import { DataService } from "../service/DataService";
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
import * as model from '../model/index';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
declare var $: any;

@Component({
  selector: 'app-clubs',
  templateUrl: './clubs.component.html',
  styleUrls: ['./clubs.component.css']
})
export class ClubsComponent implements OnInit {
  Clubform: FormGroup;
  addClubform: model.Club = {};
  userobjid: string;
  displayedColumns : String[] = ['ClubID', 'ClubName', 'TotalSlots', 'AvailableSlots', 'View', 'edit', 'delete'];
  dataSource : any;
  userList: any;
  Clublist: any = [];
  ClubID: string;
  userslist: any = [];
  @ViewChild('paginator') paginator: MatPaginator;
  constructor(private dataService: DataService,
    private toaster: ToastrService,private serviceProvider: ArticleService, private router: Router) { }

  ngOnInit(): void {
    this.addformControl(null);
    this.getClub();
  }

  addformControl(data){
    if(data === null){
      this.Clubform = new FormGroup({
        ClubID: new FormControl(''),
        ClubName: new FormControl(''),
        TotalSlots: new FormControl(''),
        AvailableSlots: new FormControl(''),
        Description: new FormControl(''),
      });
      this.ClubID = '';
    } else {      
      this.Clubform = new FormGroup({
        ClubID: new FormControl(data.ClubID),
        ClubName: new FormControl(data.ClubName),
        TotalSlots: new FormControl(data.TotalSlots),
        AvailableSlots: new FormControl(data.AvailableSlots),
        Description: new FormControl(data.Description),
      });
    }
  }

  getClub(){
    this.serviceProvider.getClub().subscribe(result=>{
      this.Clublist = result;
      this.dataSource = new MatTableDataSource<any>(result);
      this.dataSource.paginator = this.paginator;
    });
  }

  addClub(){
    this.addformControl(null);
    $('#Clubmodal').modal("show");
  }

  editClub(id){
    this.ClubID = id;
    this.serviceProvider.getClubByID(id).subscribe(result=>{
      this.addformControl(result[0]);
      this.Clubform.controls[`ClubID`].setValue(result[0].ClubID);
      this.Clubform.controls[`ClubName`].setValue(result[0].ClubName);
      this.Clubform.controls[`TotalSlots`].setValue(result[0].TotalSlots);
      this.Clubform.controls[`AvailableSlots`].setValue(result[0].AvailableSlots);
      this.Clubform.controls[`Description`].setValue(result[0].Description);
      $('#Clubmodal').modal("show");
    });
  }

  deleteClub(id){
    this.ClubID = id;
    $('#deletemodal').modal('show');
  }

  deleteConfirm() {
    $('#deletemodal').modal('hide');
    this.serviceProvider.deleteClub(this.ClubID).subscribe(result=>{
      this.toaster.success("Deleted Successfully");
    });
    window.location.reload();
    this.getClub();
  }

  Submit() {
    $('#confirmationmodal').modal('show');
  }

  Confirm(){
    this.addClubform.ClubID = this.Clubform.controls[`ClubID`].value;
    this.addClubform.ClubName = this.Clubform.controls[`ClubName`].value;
    this.addClubform.TotalSlots = this.Clubform.controls[`TotalSlots`].value;
    this.addClubform.AvailableSlots = this.Clubform.controls[`AvailableSlots`].value;
    this.addClubform.Description = this.Clubform.controls[`Description`].value;
    this.serviceProvider.createClub(this.ClubID,this.addClubform).subscribe(result=>{
      this.toaster.success("Submitted Successfully");
      $('#confirmationmodal').modal('hide');
      this.ClubID = '';
      window.location.reload();
    }); 
  }

  ViewClubusers(id){
    this.serviceProvider.getClubUsers(id).subscribe(result=>{
      this.userslist = result;
      if(this.userslist.length !== 0){
        $('#ViewUsersmodal').modal("show");
      } else {
        this.toaster.error("No Users Available");
      }
    });
  }
}

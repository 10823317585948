import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivityHelper } from '../activity/helpers/helper';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const header = JSON.parse(localStorage.getItem("token"));
        if (!ActivityHelper.IfNullOrUndefined(header)) {
            return next.handle(httpRequest.clone({
                setHeaders: {
                    Authorization: header
                }
            }));
        } else {
            return next.handle(httpRequest.clone());
        }

    }
}
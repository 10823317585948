import { Component, Input, OnInit, Inject, ViewChild, AfterContentInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ArticleService } from '../service/article.service';
import { SigninComponent } from '../signin/signin.component';
import { ToastrService } from "ngx-toastr";
import { environment } from "../../environments/environment";
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import * as moment from 'moment';
import { SelectionModel } from '@angular/cdk/collections';
import { ContactUs, EmailApiKeys, EmailTemplate } from '../model/index';
import { inArray } from 'jquery';
import { concat } from 'rxjs';
import { map } from "rxjs/operators";
import { Model } from 'mongoose';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isfooter1: boolean;
  footerdesc: string;
  public url = environment.url;

  constructor(private serviceProvider: ArticleService, private toastr: ToastrService, private router: Router) {
    this.tokenChecking();
  }

  ngOnInit(): void {
    // var path = location.pathname;
    // var submodulerights = sessionStorage.getItem('submodulerights').split(",");
    // if(submodulerights.indexOf(path) === -1){
    //   this.router.navigate(['/signin']);
    // } 
    this.tokenChecking();
    this.footerdesc = "ABC.Com envisions to ensure and disseminate quality higher education that is affordable among all learners in general and among students.";    
  }

  tokenChecking() {
    if (localStorage.getItem("token") === null) {
      this.isfooter1 = false;

    }
    else if (localStorage.getItem("token") !== null) {
      this.isfooter1 = true;
    }
  }

}

<div id="wrapper-section">
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <h2 style='background-color: #fff;'>Manage Users
                    <button mat-raised-button color="primary" class="pull-right" (click)="adduser()">
                        <i class="fa fa-fw fa-plus"></i> Add User
                    </button>
                    <button mat-raised-button color="accent" class="pull-right" style='margin-right:5px;' (click)="Export()"> 
                        <i class="fa fa-fw fa-file-export"></i> Export
                    </button> 
                </h2>
                <div class="example-container mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource">
        
                        <!-- Manage Users  -->
        
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef>Name</th>
                            <td mat-cell *matCellDef="let element">{{element.Name}}</td>
                        </ng-container>
        
                        <ng-container matColumnDef="Dob">
                            <th mat-header-cell *matHeaderCellDef>Dob</th>
                            <td mat-cell *matCellDef="let element">{{element.Dob}}</td>
                        </ng-container>
        
                        <ng-container matColumnDef="EmailID">
                            <th mat-header-cell *matHeaderCellDef>Email ID</th>
                            <td mat-cell *matCellDef="let element">{{element.EmailID}}</td>
                        </ng-container>
        
                        <ng-container matColumnDef="MobileNo">
                            <th mat-header-cell *matHeaderCellDef>Mobile No</th>
                            <td mat-cell *matCellDef="let element">{{element.MobileNo}}</td>
                        </ng-container>
        
                        <ng-container matColumnDef="ClubName">
                            <th mat-header-cell *matHeaderCellDef>Club Name</th>
                            <td mat-cell *matCellDef="let element">{{element.ClubName}}</td>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef>Edit</th>
                            <td mat-cell *matCellDef="let element"><button *ngIf="element.UserID !== 'admin'" mat-raised-button class="mx-3 my-1 small_btn"
                                    color="accent" (click)="edituser(element,element._id)">Edit</button></td>
                        </ng-container>
        
                        <ng-container matColumnDef="delete">
                            <th mat-header-cell *matHeaderCellDef>Delete</th>
                            <td mat-cell *matCellDef="let element"><button mat-raised-button class="mx-3 my-1 small_btn"
                                    color="warn" [disabled]="true" (click)="deleteuser(element._id)">Delete</button></td>
                        </ng-container>
        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                    </table>
                </div>
                <mat-paginator #paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>
<div id="usermodal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-fw fa-plus-circle"></i>
                </div>
                <h4 class="modal-title" style="color:green;"><span *ngIf="userobjid === ''">ADD</span><span *ngIf="userobjid !== ''">EDIT</span> USER</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <form [formGroup]="userform">
                    <div class="row">
                        <!-- <div class="row" *ngIf="userobjid !== ''">
                            <div class="col-sm-6" *ngIf="profile_picturefilename === ''">
                                <input type="file" (change)="fileSelectEvent($event,1)"
                                    accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF"
                                    formControlName="profile_picture">
                                <div class="col-md-4">
                                    <span *ngIf="!!profile_picture">File Size: Max
                                        2MB</span>
                                    <span *ngIf="!profile_picture" style="color: red;">File
                                        Size:
                                        Max 2MB</span>
                                </div>
                            </div>
                            <div class="col-sm-6" *ngIf="profile_picturefilename === ''">
                                <button type="button" class="btn btn-primary"
                                    style="position: relative;left: 22px;"
                                    (click)="profilepicturefilesave()">
                                    <i class="fa fa-fw fa-upload"></i> Upload
                                </button>
                            </div>
                            <div class="col-sm-3" *ngIf="profile_picturefilename !== ''">
                                <h3 id="parentdiv">Profile Picture</h3>
                            </div>
                            <div class="col-sm-9" *ngIf="profile_picturefilename !== ''">
                                <img src="{{profile_picturefilename}}" width="100" height="100" /><br/>
                                <button type="button" mat-raised-button class="btn btn-primary"
                                    (click)="profilepictureview()">
                                    <i class="fa fa-fw fa-download"></i> Download
                                </button><br/>
                                <button type="button" name="Delete" mat-raised-button color="warn"
                                    (click)="profile_picturedelete()"><i class="fa fa-fw fa-trash"></i>
                                    Delete</button>
                            </div>
                        </div>                        -->
                        <mat-form-field class="example-full-width" name="UserID" class="col-md-4">
                            <input type="text" matInput placeholder="User ID"
                                formControlName="UserID">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="Name" class="col-md-4">
                            <input type="text" matInput placeholder="Name"
                                formControlName="Name">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="Dob" class="col-md-4">
                            <mat-label>Date of Birth</mat-label>
                            <input matInput placeholder="DD-MM-YYYY" type="date" formControlName="Dob">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="EmailID" class="col-md-4">
                            <input type="text" formControlName="EmailID" matInput
                                placeholder="Email ID">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="MobileNo" class="col-md-4">
                            <span matPrefix>+91 &nbsp;</span>
                            <input type="number" formControlName="MobileNo" matInput
                                placeholder="Mobile No" minlength="10" maxlength="10">
                        </mat-form-field>

                        <mat-form-field class="example-full-width" name="ClubID" class="col-md-4">
                            <mat-label>Preference</mat-label>
                            <mat-select formControlName="ClubID" (ngModelChange)="changepreference($event)" >
                                <mat-option value="">-Select-</mat-option>
                                <mat-option *ngFor="let data of Clublist" value="{{data._id}}">
                                    {{data.ClubName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
                <button mat-raised-button color="primary" (click)="Submit()"> Submit </button>
            </div>
        </div>
    </div>
</div>

<div id="confirmationmodal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-fw fa-times-circle-o"></i>
                </div>
                <h4 class="modal-title">Are you sure?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <p>Do you really want to update these record?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
                <button mat-raised-button color="accent" (click)="Confirm()">Confirm</button>
            </div>
        </div>
    </div>
</div>

<div id="deleteusermodal" class="modal fade">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-fw fa-times-circle-o"></i>
                </div>
                <h4 class="modal-title">Are you sure?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <p>Do you really want to delete these records? This process cannot be undone.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" (click)="deleteConfirm()">Delete</button>
            </div>
        </div>
    </div>
</div>

<div id="profilepicturemodal" class="modal fade delete-twelveb">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="material-icons">&#xE5CD;</i>
                </div>
                <h4 class="modal-title">Are you sure?</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <p>Do you really want to delete these records? This process cannot be undone.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-info" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" (click)="deleteprofilepictureConfirm()">Delete</button>
            </div>
        </div>
    </div>
</div>
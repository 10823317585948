import { Component } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlatformLocation } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ABC.Com';
  
  constructor(location: PlatformLocation) {

    location.onPopState(() => {

        console.log('pressed back!');
       
    });

}
}
